/******* screen-small.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
@font-face {
  font-family: "FrutigerLight";
  src: url("/extras/fonts/font.woff2") format("woff2"), url("/extras/fonts/font.woff") format("woff");
}
@font-face {
  font-family: "FrutigerBold";
  src: url("/extras/fonts/font-2.woff2") format("woff2"), url("/extras/fonts/font-2.woff") format("woff");
}
@font-face {
  font-family: "Isonorm";
  src: url("/extras/fonts/font-1.woff2") format("woff2"), url("/extras/fonts/font-1.woff") format("woff");
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 12px;
  margin-bottom: 12px;
}
.unit > .body,
.unit > form > .body {
  margin: 0 0;
  width: 100%;
}
.cb-part-body {
  margin-top: 0;
  margin-bottom: 0;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 12px;
  margin-bottom: 12px;
}
.lead {
  margin-bottom: 0 !important;
}
video {
  float: left;
  width: 100%;
}
.gist,
.dent {
  display: list-item;
  list-style-position: outside;
}
.gist {
  margin-left: 16px;
}
.dent {
  margin-left: 32px;
}
.line {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  font-size: 0;
  line-height: 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #4d4d4d;
  color: #4d4d4d;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
  margin-left: 14px;
}
.link,
.load {
  background-repeat: no-repeat !important;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #000 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
.grid table {
  border-collapse: collapse;
}
.cb-table-layout-fixed {
  table-layout: fixed;
}
.grid td,
.grid th {
  border-width: 0;
  border-style: solid;
  border-color: #b3b3b3;
  vertical-align: top;
}
.horz td,
.horz th {
  padding: 1px;
}
.vert td,
.vert th {
  padding: 1px 4px;
}
.horz .trim td,
.horz .trim th {
  border-width: 1px 0;
}
.vert .trim td,
.vert .trim th {
  border-width: 0 1px;
}
.grid th {
  font-weight: normal;
  font-family: 'FrutigerBold', helvetica, sans-serif;
  background: #ccc;
  text-align: left;
}
.vert > .beam td.odd,
.horz > .beam > .odd {
  background: #e6e6e6;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #404040;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #404040;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset {
  padding: 0.2em 0 0.5em;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: normal;
  font-family: 'FrutigerBold', helvetica, sans-serif;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding-bottom: 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  margin-right: 15px;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #404040;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
#disp.srch a:visited {
  color: #da8b00;
}
.srch .text {
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #bfbfbf;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  padding-bottom: 0.8em;
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #f39b00;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #f39b00;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #f39b00;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #f39b00;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@media screen and (max-width: 850px) {
  .cb-table-3up.vert {
    position: relative;
    display: block;
    border: 1px solid #b3b3b3;
  }
  .cb-table-3up.vert td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-3up.vert > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-3up.vert > tbody tr {
    display: inline-block;
    border-left: 1px solid #b3b3b3;
  }
  .cb-table-3up.vert > tbody tr:first-child {
    border: 0;
  }
  .cb-table-head .cb-table-3up.vert > thead {
    display: block;
    float: left;
  }
  .cb-table-head .cb-table-3up.vert > thead > tr {
    display: block;
  }
  .cb-table-head .cb-table-3up.vert > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot {
    display: block;
    float: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot > tr {
    display: block;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-both .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 1px solid #b3b3b3;
  }
  .cb-table-both .cb-table-3up.horz td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-both .cb-table-3up.horz > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr {
    display: inline-block;
    border-left: 1px solid #b3b3b3;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr:first-child {
    border: 0;
  }
  .cb-table-both .cb-table-3up.horz > thead {
    display: block;
    float: left;
  }
  .cb-table-both .cb-table-3up.horz > thead > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot {
    display: block;
    float: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-head .vert tr.odd {
    background: #e6e6e6;
  }
  .cb-table-head .vert td {
    background: none !important;
  }
  .cb-table-column-inactive {
    position: relative;
  }
  .cb-table-column-inactive th {
    max-width: 1em;
    overflow: hidden;
    white-space: nowrap;
  }
  .cb-table-column-inactive > tr:after {
    top: 0;
    position: absolute;
    left: 0;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    content: '…';
    display: inline-block;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tbody,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tbody,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    display: block;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot {
    font-size: 0;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead tr,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot tr {
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th {
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    border: 1px solid #b3b3b3;
    margin-bottom: 4px;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    position: relative;
    padding: 1px 4px;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    font-weight: normal;
    font-family: 'FrutigerBold', helvetica, sans-serif;
    position: absolute;
    top: 0;
    overflow: hidden;
    padding: 1px 4px;
    width: 35%;
    border: 0;
    content: attr(data-title);
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    *behavior: url(/objects/boxsizing/boxsizing.htc);
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td {
    padding-left: 38%;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after {
    left: 0;
    padding-right: 1%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    padding-right: 38%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    right: 0;
    padding-left: 1%;
  }
  .cb-table-none .cb-table-3up.horz {
    display: block;
    border: 0;
  }
  .cb-table-none .cb-table-3up.horz tbody,
  .cb-table-none .cb-table-3up.horz th,
  .cb-table-none .cb-table-3up.horz tr,
  .cb-table-none .cb-table-3up.horz td {
    display: block;
  }
  .cb-table-none .cb-table-3up.horz tr {
    border: 1px solid #b3b3b3;
    margin-bottom: 4px;
  }
  .cb-table-none .cb-table-3up.horz td {
    padding: 1px 4px;
    border: 0;
  }
  .cb-table-columns-3 tr {
    min-width: 50%;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
/******* site.less 2013-1-16 *******/
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(243, 155, 0, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  font-size: 14px;
  font-size: 1.4rem;
  width: 100%;
  background-color: transparent;
  color: #000;
  font-family: 'FrutigerLight', helvetica, sans-serif;
  line-height: 1.2;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 100%;
  width: 800px;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
#home {
  float: left;
  width: 208px;
  margin: 31px 0;
}
.foothome {
  float: left;
  width: 208px;
}
.logo {
  width: 100%;
  height: auto;
}
#head {
  float: left;
  background: #000;
  border-radius: 0 25px 0 0;
  position: absolute;
  bottom: 0;
}
#head:after {
  content: '';
  position: absolute;
  top: 0;
  right: 100%;
  margin-right: -1px;
  width: 10000px;
  height: 100%;
  background: #000;
}
.cb-layout3 #head {
  width: 100%;
  border-radius: 0;
  background: none;
  position: relative;
  margin-top: 45px;
}
.cb-layout3 #head:after {
  display: none;
}
.infraMap {
  float: left;
}
#wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.section {
  float: left;
  width: 100%;
}
.section--header {
  position: relative;
  z-index: 2000;
  background: #fff;
}
.section--topservices {
  position: absolute;
  z-index: 20;
}
.section--topservices .desk {
  position: relative;
}
#edit .section--topservices {
  position: relative;
  display: none;
}
#edit.cb-layout3 .section--topservices {
  display: block;
}
.section--homemood {
  display: none;
}
.cb-layout3 .section--homemood {
  display: block;
}
.section--mood {
  position: relative;
}
.cb-layout3 .section--mood {
  display: none;
}
#edit .section--mood {
  min-height: 200px;
}
.section--base {
  background: #f8cd85;
}
.c2 .section--base {
  background: #c4ddf1;
}
.c3 .section--base {
  background: #f5b5ae;
}
.c4 .section--base {
  background: #cadea5;
}
.c5 .section--base {
  background: #f5e89b;
}
.c6 .section--base {
  background: #f5b5ae;
}
.c7 .section--base {
  background: #f5b5ae;
}
.section--base a:hover,
.section--base a:focus {
  color: #000 !important;
}
#edit .section--fareast {
  border-top: 3px dashed #ccc;
}
.cb-layout3 .section--fareast {
  display: none;
}
#edit .section--south {
  border-top: 3px dashed #ccc;
}
.cb-layout3 .section--south {
  display: none;
}
.section--footer {
  position: relative;
  background: url(/images/foot.jpg) no-repeat 50% 0;
  background-size: cover;
}
.section--areaTen {
  display: none;
  box-shadow: 0 0 6px rgba(120, 120, 120, 0.4);
  margin-top: 12px;
}
#edit.cb-layout3 .section--areaTen,
#view.cb-layout3:not(.areaTen--empty) .section--areaTen {
  display: block;
}
.section--areaEleven {
  background-color: #f39b00;
}
.section--areaEleven p:not(.loud) a {
  color: #fff;
}
.section--areaEleven p:not(.loud) a:hover,
.section--areaEleven p:not(.loud) a:focus {
  text-decoration: underline;
}
.im-mood {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.im-mood__part {
  float: left;
  width: 25%;
  position: relative;
}
.im-mood__part.blue {
  background: #8abde5;
}
.im-mood__part.red {
  background: #eb6c5f;
}
.im-mood__part.green {
  background: #96c047;
}
.im-mood__part.yellow {
  background: #edd32f;
}
@media (max-width: 499px) {
  .im-mood__part {
    width: 100%;
  }
}
.im-mood__pic {
  float: left;
  width: 100%;
  display: block;
  position: relative;
  height: 474px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.im-mood__part--wasser .im-mood__pic {
  background-image: url(/images/im-mood-blue-2022-08-26.jpg);
}
.im-mood__part--strom .im-mood__pic {
  background-image: url(/images/im-mood-red-2022-08-26.jpg);
}
.im-mood__part--waerme .im-mood__pic {
  background-image: url(/images/im-mood-green-2022-08-26.jpg);
}
.im-mood__part--estrom .im-mood__pic {
  background-image: url(/images/im-mood-estrom-2023-01-16.jpg);
}
.im-mood__part--gis .im-mood__pic {
  background-image: url(/images/im-mood-yellow-2022-08-26.jpg);
}
.im-mood__part--solar .im-mood__pic {
  background-image: url(/images/im-mood-solar-2023-01-16.jpg);
}
@media (max-width: 499px) {
  .im-mood__pic {
    height: 132px;
  }
}
.im-mood__partGroup {
  float: left;
  width: 25%;
  display: flex;
  flex-direction: column;
}
.im-mood__partGroup .im-mood__part {
  width: 100% !important;
  flex-grow: 1;
}
.im-mood__partGroup .im-mood__pic {
  height: 100%;
}
@media (max-width: 499px) {
  .im-mood__partGroup {
    width: 100%;
  }
  .im-mood__partGroup .im-mood__pic {
    height: 132px;
  }
}
.im-mood__claim {
  position: absolute;
  right: 24px;
  top: 24px;
  min-width: 60px;
  background-size: 60px auto;
  background-repeat: no-repeat;
  background-position: 50% 0;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Isonorm', helvetica, sans-serif;
  text-align: center;
  padding-top: 64px;
  font-size: 16px;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
.im-mood__part--wasser .im-mood__claim {
  background-image: url(/images/pin-wasser.svg);
}
.im-mood__part--strom .im-mood__claim {
  background-image: url(/images/pin-strom.svg);
}
.im-mood__part--waerme .im-mood__claim {
  background-image: url(/images/pin-waerme.svg);
}
.im-mood__part--estrom .im-mood__claim {
  background-image: url(/images/pin-estrom.svg);
}
.im-mood__part--gis .im-mood__claim {
  background-image: url(/images/pin-gis.svg);
}
.im-mood__part--solar .im-mood__claim {
  background-image: url(/images/pin-solar.svg);
}
.moodcontent {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  height: 0;
}
.moodcontent .desk {
  position: relative;
}
#cb-cookie-warning {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2000;
  background-color: #f39b00;
  display: flex;
  justify-content: center;
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  display: none;
}
.cb-cookie-warning--container {
  float: left;
  width: 800px;
  max-width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}
@media (max-width: 639px) {
  .cb-cookie-warning--container {
    flex-direction: column;
    padding: 10px 0;
  }
}
.cb-cookie-warning--text {
  float: left;
  max-width: 740px;
  font-weight: normal;
  font-family: 'FrutigerBold', helvetica, sans-serif;
}
.cb-cookie-warning--text a {
  color: #fff;
}
.cb-cookie-warning--actions {
  float: left;
  margin-left: 40px;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 20px;
  flex-direction: row-reverse;
}
@media (max-width: 849px) {
  .cb-cookie-warning--actions {
    margin-left: 20px;
  }
}
@media (max-width: 639px) {
  .cb-cookie-warning--actions {
    margin-left: 0;
    margin-top: 10px;
  }
}
.cb-cookie-warning__button {
  margin-left: 0 !important;
  display: inline-block;
  padding-right: 76px;
  background-size: 56px 8px;
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-white.svg);
  color: #fff;
  transition: all 0.3s;
}
.cb-cookie-warning__button:hover,
.cb-cookie-warning__button:focus {
  background-position: right center;
}
.footcontent {
  float: left;
  width: 100%;
  background: #fff;
  margin-top: 24px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  padding: 24px;
}
.footcontent a {
  color: #000 !important;
}
.footcontent a:hover,
.footcontent a:focus {
  color: #f39b00 !important;
}
.footarea {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.footpart {
  float: left;
  margin-right: 76px;
  margin-top: 24px;
}
.footpart.exit {
  margin-right: 0;
}
.foottitle {
  display: block;
  font-weight: normal;
  font-family: 'FrutigerBold', helvetica, sans-serif;
}
.foottitle.orange {
  color: #f39b00;
}
.vcard {
  float: left;
}
.vcard .adr,
.vcard .street-address,
.vcard .tel,
.vcard .footlink {
  display: block;
}
#services {
  float: left;
  display: block;
}
#services .meta {
  display: block;
}
.servicetoggle {
  float: right;
  margin: 20px 0;
  width: 60px;
  height: 60px;
  background: url(/images/pin-service.svg) no-repeat 50% 50%;
  background-size: 60px 60px;
  -o-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -webkit-transition: all 0.15s;
  transition: all 0.15s;
  animation-name: pulse;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}
.servicetoggle:hover,
.servicetoggle:focus {
  cursor: pointer;
}
.servicetoggle.cb-toggle-active {
  -moz-transform: rotate(40deg);
  -o-transform: rotate(40deg);
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
}
@keyframes pulse {
  0% {
    background-size: 60px 60px;
  }
  10% {
    background-size: 50px 50px;
  }
  40% {
    background-size: 60px 60px;
  }
  100% {
    background-size: 60px 60px;
  }
}
.topservices {
  position: absolute;
  right: 0;
  top: 100px;
  background: #f39b00;
  width: 372px;
  max-width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 20px;
  border-radius: 0 0 8px 8px;
  -o-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  max-height: 0;
  overflow: hidden;
}
.topservices.cb-toggle-target-active {
  max-height: 1300px;
  margin-bottom: 20px;
}
#edit .topservices {
  position: relative;
  top: 0;
  max-height: none;
  margin-bottom: 20px;
}
.topservice__content {
  float: left;
  width: 100%;
  margin: 50px 0 20px;
  -o-transition: all 0.4s 0.1s;
  -moz-transition: all 0.4s 0.1s;
  -webkit-transition: all 0.4s 0.1s;
  transition: all 0.4s 0.1s;
  opacity: 0;
  position: relative;
  top: -40px;
}
.topservices.cb-toggle-target-active .topservice__content {
  top: 0;
  opacity: 1;
}
#edit .topservice__content {
  top: 0;
  opacity: 1;
}
.toplink {
  position: absolute;
  right: 0;
  bottom: 100%;
  margin-bottom: 20px;
  background: #000 url(/images/toplink.svg) no-repeat 100% 50%;
  color: #fff;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
  line-height: 24px;
  padding: 2px 30px 0 10px;
}
.toplink:hover,
.toplink:focus {
  cursor: pointer;
}
.cb-layout3 .toplink {
  display: none;
}
#social .meta {
  float: left;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
}
#social .meta:last-child {
  margin-right: 0;
}
#social .meta.service_facebook {
  mask-image: url(/images/icon-facebook.svg);
}
#social .meta.service_linkedin {
  mask-image: url(/images/icon-linkedin.svg);
}
.section--header #social {
  float: right;
  margin: 31px 16px 31px 0;
}
.section--header #social .meta {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  background-color: #000;
}
.section--header #social .meta:last-child {
  margin-right: 0;
}
.section--header #social .meta:hover,
.section--header #social .meta:focus {
  background-color: #f39b00;
}
#mobile-navi #social {
  float: left;
  display: block;
  margin: 30px 40px;
}
@media (max-width: 639px) {
  #mobile-navi #social {
    margin: 30px;
  }
}
#mobile-navi #social .meta {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  background-color: #fff;
}
#mobile-navi #social .meta:last-child {
  margin-right: 0;
}
h1 {
  color: #fff;
  font-size: 39px;
  line-height: 42px;
  padding: 4px 20px 4px 0;
  font-weight: normal;
  font-family: 'Isonorm', helvetica, sans-serif;
}
.cb-layout3 h1 {
  color: #000;
  padding: 0;
  font-family: 'FrutigerLight', helvetica, sans-serif;
  font-size: 28px;
  line-height: 1.2;
  text-align: center;
}
h2,
h3 {
  font-size: 28px;
  font-weight: normal;
}
strong {
  font-weight: normal;
  font-family: 'FrutigerBold', helvetica, sans-serif;
}
.loud {
  background-color: #fff;
  padding: 20px;
}
.pale {
  color: #4d4d4d;
}
.skew {
  letter-spacing: 0.03em;
  font-style: italic;
  font-family: georgia, serif;
}
.text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.open,
.load {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
.gist,
.dent {
  list-style: disc;
}
a {
  text-decoration: none;
  color: #f39b00;
}
.c2 a {
  color: #8abde5;
}
.c3 a {
  color: #eb6c5f;
}
.c4 a {
  color: #96c047;
}
.c5 a {
  color: #edd32f;
}
.c6 a {
  color: #eb6c5f;
}
.c7 a {
  color: #eb6c5f;
}
a:hover,
a:focus {
  color: #f8cd85;
}
.c2 a:hover,
.c2 a:focus {
  color: #c4ddf1;
}
.c3 a:hover,
.c3 a:focus {
  color: #f5b5ae;
}
.c4 a:hover,
.c4 a:focus {
  color: #cadea5;
}
.c5 a:hover,
.c5 a:focus {
  color: #f5e89b;
}
.c6 a:hover,
.c6 a:focus {
  color: #f5b5ae;
}
.c7 a:hover,
.c7 a:focus {
  color: #f5b5ae;
}
.link {
  background: url('/icon-link/link.gif') no-repeat 0 0.3em;
}
.link.mail {
  background-image: url('/icon-link/mail.gif');
  background-position: 0 0.5em;
}
.load {
  background-position: 0 0.1em;
}
.rss {
  background-image: url('/icon-link/rss_small.gif');
}
div.list + div.list {
  margin-top: -12px;
}
div.link {
  background: none !important;
}
div.link a.open {
  margin-left: 0 !important;
  display: inline-block;
  padding-right: 76px;
  background-size: 56px 8px;
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-orange.svg);
  color: #f39b00;
  transition: all 0.3s;
}
div.link a.open:hover,
div.link a.open:focus {
  color: #f39b00;
  background-position: right center;
}
div.area:empty {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
div.main {
  margin-top: 33px;
  margin-bottom: 44px;
}
.cb-layout1 div.main {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.cb-layout3 div.main {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 0;
  text-align: center;
}
div.areaTen {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 56px;
  margin-bottom: 56px;
  text-align: center;
}
div.areaEleven {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
div.base {
  margin-top: 30px;
  margin-bottom: 30px;
}
div.base div.unit {
  margin-top: 0;
  margin-bottom: 0;
}
div.base div.unit div.head,
div.base div.unit div.body,
div.base div.unit div.foot {
  float: none;
  margin: 0 auto;
  *zoom: 1;
  max-width: 100%;
}
div.base div.unit div.head:before,
div.base div.unit div.body:before,
div.base div.unit div.foot:before,
div.base div.unit div.head:after,
div.base div.unit div.body:after,
div.base div.unit div.foot:after {
  display: table;
  content: '';
}
div.base div.unit div.head:after,
div.base div.unit div.body:after,
div.base div.unit div.foot:after {
  clear: both;
}
div.base div.unit div.head h2,
div.base div.unit div.part {
  margin-left: 0 ;
  margin-right: 0;
}
div.base div.unit.fold div.text a.open,
div.base div.unit.fold div.lead a.open,
div.base div.unit.fold div.list a.open {
  color: #000;
  text-decoration: underline;
}
div.base div.unit.fold div.text a.open:hover,
div.base div.unit.fold div.lead a.open:hover,
div.base div.unit.fold div.list a.open:hover,
div.base div.unit.fold div.text a.open:focus,
div.base div.unit.fold div.lead a.open:focus,
div.base div.unit.fold div.list a.open:focus {
  color: #fff !important;
}
div.base div.unit.fold div.head h2 {
  line-height: 32px;
  padding: 24px 0 24px 84px;
  background: url(/images/pin-service.svg) no-repeat 0 50%;
  background-size: 60px auto;
  color: #f39b00;
}
div.base div.unit.fold div.head h2 .edit {
  background: none !important;
}
.c2 div.base div.unit.fold div.head h2 {
  background-image: url(/images/pin-wasser.svg);
  color: #8abde5;
}
.c3 div.base div.unit.fold div.head h2 {
  background-image: url(/images/pin-strom.svg);
  color: #eb6c5f;
}
.c4 div.base div.unit.fold div.head h2 {
  background-image: url(/images/pin-waerme.svg);
  color: #96c047;
}
.c5 div.base div.unit.fold div.head h2 {
  background-image: url(/images/pin-gis.svg);
  color: #edd32f;
}
.c6 div.base div.unit.fold div.head h2 {
  background-image: url(/images/pin-estrom.svg);
  color: #eb6c5f;
}
.c7 div.base div.unit.fold div.head h2 {
  background-image: url(/images/pin-solar.svg);
  color: #eb6c5f;
}
div.base div.unit.fold div.body {
  width: 100%;
  background: #f39b00;
}
.c2 div.base div.unit.fold div.body {
  background: #8abde5;
}
.c3 div.base div.unit.fold div.body {
  background: #eb6c5f;
}
.c4 div.base div.unit.fold div.body {
  background: #96c047;
}
.c5 div.base div.unit.fold div.body {
  background: #edd32f;
}
.c6 div.base div.unit.fold div.body {
  background: #eb6c5f;
}
.c7 div.base div.unit.fold div.body {
  background: #eb6c5f;
}
div.base div.unit.fold div.body div.more {
  float: none;
  margin: 60px auto;
  *zoom: 1;
  max-width: 100%;
  max-height: 10000px;
  overflow: hidden;
  -o-transition: margin 0.218s, opacity 0.218s 0.13s, max-height 3s;
  -moz-transition: margin 0.218s, opacity 0.218s 0.13s, max-height 3s;
  -webkit-transition: margin 0.218s, opacity 0.218s 0.13s, max-height 3s;
  transition: margin 0.218s, opacity 0.218s 0.13s, max-height 3s;
  opacity: 1;
}
div.base div.unit.fold div.body div.more:before,
div.base div.unit.fold div.body div.more:after {
  display: table;
  content: '';
}
div.base div.unit.fold div.body div.more:after {
  clear: both;
}
div.base div.unit.fold div.body div.more.cb-invisible {
  display: block !important;
  max-height: 0;
  margin-top: 0;
  opacity: 0;
  margin-bottom: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
div.base div.unit.fold div.ctrl {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}
#edit div.base div.unit.fold div.ctrl {
  height: 0;
}
#edit div.base div.unit.fold div.ctrl a {
  float: right;
  height: 80px;
  text-align: center;
  width: 80px;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#view div.base div.unit.fold div.ctrl a {
  float: left;
  width: 100%;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  height: 80px;
}
#view div.base div.unit.fold div.ctrl a:hover,
#view div.base div.unit.fold div.ctrl a:focus {
  cursor: pointer;
}
#view div.base div.unit.fold div.ctrl a.less {
  background: url(/images/close-fold.svg) no-repeat 100% 50%;
  background-size: 20px auto;
}
.base .link > .open,
.base .load {
  color: #fff !important;
}
.base .link > .open:hover,
.base .link > .open:focus,
.base .load:hover,
.base .load:focus {
  text-decoration: underline;
}
div.farwest {
  margin-top: 33px;
  margin-bottom: 25px;
}
div.south {
  margin-top: 33px;
  margin-bottom: 44px;
}
.cb-layout1 div.south {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
div.fareast {
  width: 100%;
}
div.fareast .unit {
  width: 100%;
  margin: 0;
}
div.fareast .unit .head h2,
div.fareast .unit .part {
  margin: 0;
}
div.fareast .unit .part {
  width: 100%;
}
div.fareast .unit .part.pict img {
  max-width: 100% !important;
  max-height: 100000px !important;
}
div.north {
  width: 100%;
}
div.north * {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
div.north a {
  color: #000;
}
div.north a:hover,
div.north a:focus {
  text-decoration: underline;
}
div.north div.unit {
  width: 100%;
  margin: 10px 0;
}
div.north div.unit:first-child {
  margin-top: 0;
}
div.north div.unit div.head h2 {
  margin: 0;
  padding-bottom: 20px;
}
div.north div.unit div.part {
  width: 100%;
  margin: 0;
}
div.north div.unit.pure div.link {
  border: 1px solid #fff;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px;
  background: none !important;
}
div.north div.unit.pure div.link a.open {
  margin-left: 0;
  font-size: 28px;
  color: #fff;
  padding: 5px 10px;
  text-decoration: none;
}
div.north div.unit.pure div.link a.open:before {
  display: none;
}
div.north div.unit.pure div.link a.open:hover,
div.north div.unit.pure div.link a.open:focus {
  background: #fff;
  color: #f39b00;
  text-decoration: none;
}
div.north div.unit.seam {
  border: 1px solid #fff;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 10px;
}
div.north div.unit.seam h2 {
  color: #fff;
  padding-bottom: 12px !important;
}
div.north div.unit.seam:hover,
div.north div.unit.seam:focus {
  color: #f39b00;
  background: #fff;
}
div.north div.unit.seam:hover h2,
div.north div.unit.seam:focus h2 {
  color: #f39b00;
}
#view div.north div.unit.seam div.link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#view div.north div.unit.seam div.link a.open {
  width: 100%;
  height: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
.cb-layout1 .main .seam,
.cb-layout1 .south .seam,
.cb-layout3 .main .seam,
.areaTen .seam {
  background-color: #fff;
  box-shadow: 0 0 6px rgba(120, 120, 120, 0.4);
  box-sizing: border-box;
  padding: 2px 14px;
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: left;
}
.cb-layout1 .main .seam + .seam + .seam + .seam,
.cb-layout1 .south .seam + .seam + .seam + .seam,
.cb-layout3 .main .seam + .seam + .seam + .seam,
.areaTen .seam + .seam + .seam + .seam {
  margin-top: 0;
}
.cb-layout1 .main .seam div.head,
.cb-layout1 .south .seam div.head,
.cb-layout3 .main .seam div.head,
.areaTen .seam div.head {
  margin-top: 12px;
  margin-bottom: 12px;
}
.cb-layout1 .main .seam div.head h2,
.cb-layout1 .south .seam div.head h2,
.cb-layout3 .main .seam div.head h2,
.areaTen .seam div.head h2 {
  font-size: 22px;
  line-height: 1.18181818;
}
.cb-layout1 .main .seam .part,
.cb-layout1 .south .seam .part,
.cb-layout3 .main .seam .part,
.areaTen .seam .part,
.cb-layout1 .main .seam .head h2,
.cb-layout1 .south .seam .head h2,
.cb-layout3 .main .seam .head h2,
.areaTen .seam .head h2 {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
@media (max-width: 849px) {
  .cb-layout1 .main .seam + .seam + .seam,
  .cb-layout1 .south .seam + .seam + .seam,
  .cb-layout3 .main .seam + .seam + .seam,
  .areaTen .seam + .seam + .seam {
    margin-top: 0;
  }
}
@media (max-width: 639px) {
  .cb-layout1 .main .seam + .seam,
  .cb-layout1 .south .seam + .seam,
  .cb-layout3 .main .seam + .seam,
  .areaTen .seam + .seam {
    margin-top: 0;
  }
}
div.areaEleven .unit {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
div.areaEleven .unit .part,
div.areaEleven .unit div.head h2 {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
div.areaEleven .pure {
  max-width: 352px;
}
div.areaEleven .seam {
  text-align: left;
}
div.form div.fake {
  width: auto !important;
}
div.form div.foot {
  padding-bottom: 0;
}
div.form .cb-form-required {
  margin-top: 15px;
}
div.form div.part {
  margin-top: 15px;
  margin-bottom: 15px;
}
div.form span.name,
div.form label {
  line-height: 20px !important;
  padding: 6px 0 !important;
}
div.form input.text,
div.form textarea {
  min-height: 32px;
  border-radius: 5px;
  padding: 5px 10px;
}
.section--base div.form input.text,
.section--base div.form textarea {
  border: none;
  box-shadow: none;
}
div.form input.submit {
  margin-top: 15px;
  width: 100%;
  padding: 0 10px;
  min-height: 40px;
  font-size: 28px;
  border-radius: 5px;
  background: #f39b00;
}
.c2 div.form input.submit {
  background: #8abde5;
}
.c3 div.form input.submit {
  background: #eb6c5f;
}
.c4 div.form input.submit {
  background: #96c047;
}
.c5 div.form input.submit {
  background: #edd32f;
}
.c6 div.form input.submit {
  background: #eb6c5f;
}
.c7 div.form input.submit {
  background: #eb6c5f;
}
div.form input.submit:hover,
div.form input.submit:focus {
  background: #da8b00;
}
.c2 div.form input.submit:hover,
.c2 div.form input.submit:focus {
  background: #75b1e0;
}
.c3 div.form input.submit:hover,
.c3 div.form input.submit:focus {
  background: #e85748;
}
.c4 div.form input.submit:hover,
.c4 div.form input.submit:focus {
  background: #89b13d;
}
.c5 div.form input.submit:hover,
.c5 div.form input.submit:focus {
  background: #ebce18;
}
.c6 div.form input.submit:hover,
.c6 div.form input.submit:focus {
  background: #e85748;
}
.c7 div.form input.submit:hover,
.c7 div.form input.submit:focus {
  background: #e85748;
}
div.form input.submit:active {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) inset;
}
.grow {
  text-transform: none !important;
}
/* MOOD CONTAINER */
#expo {
  float: left;
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 30.20833333%;
}
/* IMAGE CONTAINER */
#slides {
  overflow: hidden;
  position: relative;
  max-width: 100% !important;
  max-height: 10000px !important;
  z-index: 1;
}
#slides .slide {
  width: 100%;
}
#slides img {
  display: block;
  width: 100%;
  max-width: 100% !important;
  max-height: 10000px !important;
}
/* MOOD NAVIGATION */
#expo div.link {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: 0;
}
#expo div.link .link {
  background: none !important;
}
#expo div.link a.link,
#expo div.link tr.cb-index-some {
  display: none;
}
#expo div.link tr.cb-index-all {
  position: absolute;
  bottom: 18px;
  left: 0;
  width: 100%;
  text-align: right;
  font-size: 0;
  line-height: 0;
}
#expo div.link tr.cb-index-all td {
  display: inline-block;
  margin-left: 12px;
}
#expo div.link tr.cb-index-all td a {
  float: left;
  display: block;
  width: 14px;
  height: 14px;
  background: #fff;
  border-radius: 50px;
}
#expo div.link tr.cb-index-all td.this a {
  background: #f39b00;
}
.c2 #expo div.link tr.cb-index-all td.this a {
  background: #8abde5;
}
.c3 #expo div.link tr.cb-index-all td.this a {
  background: #eb6c5f;
}
.c4 #expo div.link tr.cb-index-all td.this a {
  background: #96c047;
}
.c5 #expo div.link tr.cb-index-all td.this a {
  background: #edd32f;
}
.c6 #expo div.link tr.cb-index-all td.this a {
  background: #eb6c5f;
}
.c7 #expo div.link tr.cb-index-all td.this a {
  background: #eb6c5f;
}
@media only screen and (max-width: 1920px) {
  #expo {
    padding-bottom: 580px;
  }
  #slides {
    height: 580px !important;
  }
  #slides .slide {
    width: 1920px;
    height: 580px !important;
    left: 50%;
  }
  #slides .slide img {
    margin-left: -960px;
  }
}
@media only screen and (max-width: 639px) {
  #expo {
    padding-bottom: 384px;
  }
  #slides {
    height: 384px !important;
  }
  #slides .slide {
    width: 1271px;
    height: 384px !important;
    left: 50%;
  }
  #slides .slide img {
    margin-left: -635.5px;
  }
}
/******* forms.less 2014-10-16 *******/
/* Changelog

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 1.2em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #404040;
  background-color: #fff;
  font-weight: normal;
  font-family: 'FrutigerLight', helvetica, sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.1em 3px;
  min-height: 1.2em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 14px;
  font-size: 1.4rem;
  color: #444;
  font-weight: normal;
  font-family: 'FrutigerLight', helvetica, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #8c8c8c;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  -webkit-box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  -webkit-transition: -webkit-box-shadow 0.218s;
  -moz-transition: box-shadow 0.218s;
  -o-transition: box-shadow 0.218s;
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #c7d7ed;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #c7d7ed;
}
input.submit,
button.cb-hybrid {
  font-size: 14px;
  font-size: 1.4rem;
  display: inline-block;
  padding: 5px 10px;
  min-height: 1.2em;
  border-radius: 2px;
  background-color: #f39b00;
  color: #fff;
  text-align: center;
  text-decoration: none;
  line-height: 1.2em;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  border-color: #da8b00 #a66a00 #a66a00 #da8b00;
  background-color: #ffa403;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  border-color: #da8b00 #a66a00 #a66a00 #da8b00;
  background-color: #c07a00;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 1.2em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  float: left;
  width: 100%;
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
.calendar {
  position: absolute;
  z-index: 100;
  border: 1px solid #666;
  background: #333;
  text-align: right;
  line-height: 2;
  color: #fff !important;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  color: #eee;
  text-align: center;
  font-weight: 700;
}
.calendar tbody {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #666;
}
td.published {
  color: #7be;
  font-weight: 700;
}
td.show {
  background: #7be;
  color: #fff;
  font-weight: 700;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px 1px 6px;
  color: inherit;
  text-decoration: none;
}
.calendar th > a:hover,
.calendar td > a:hover {
  background: #666;
  color: #fff;
}
.calendar td.show {
  background: #87004e;
}
.calendar td.show a:hover {
  background: none;
}
/*** mobile-navi-multilevel-right-left.less 2014-11-03 ***/
.navi,
#find,
.dock {
  display: none;
}
#mobile-navi {
  position: fixed;
  top: 0;
  width: 380px;
  height: 100%;
  z-index: 1999;
  overflow: scroll;
  background: #000;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.togglenavigation {
  position: relative;
  float: right;
  margin: 40px 24px 40px 0;
  display: block;
  width: 24px;
  height: 20px;
  z-index: 2001;
  cursor: pointer;
  color: #000 !important;
  -webkit-tap-highlight-color: transparent;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
  -webkit-transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
  transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
}
.togglenavigation:active > span {
  background: #000000 !important;
}
.togglenavigation > span {
  left: 0;
  top: 50%;
  position: absolute;
  display: block;
  height: 4px;
  width: 24px;
  margin-top: -2px;
  background: currentColor;
  border-radius: 12px;
}
.togglenavigation > .tline-1 {
  margin-top: -10px;
}
.togglenavigation > .tline-4 {
  margin-top: 6px;
}
.cb-toggle-target-active .togglenavigation > .tline-1,
.cb-toggle-target-active .togglenavigation > .tline-4 {
  opacity: 0;
}
.cb-toggle-target-active .togglenavigation > .tline-2,
.cb-toggle-target-active .togglenavigation > .tline-3 {
  -webkit-transition: -webkit-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  -o-transition: -o-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  -moz-transition: -moz-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  transition: transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
}
.cb-toggle-target-active .togglenavigation > .tline-2 {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.cb-toggle-target-active .togglenavigation > .tline-3 {
  opacity: 1;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
body.cb-toggle-target-active #mobile-navi {
  -o-transition: all 0.218s ease-out;
  -moz-transition: all 0.218s ease-out;
  -webkit-transition: all 0.218s ease-out;
  transition: all 0.218s ease-out;
}
body.cb-toggle-target-active .togglenavigation {
  -o-transition: all 0.2s ease-out;
  -moz-transition: all 0.4s cubic-bezier(0.21, 0.53, 0.44, 1.24);
  -webkit-transition: all 0.4s cubic-bezier(0.21, 0.53, 0.44, 1.24);
  transition: all 0.4s cubic-bezier(0.21, 0.53, 0.44, 1.24);
}
/* RIGHT */
#mobile-navi {
  right: -380px;
}
body.cb-toggle-target-active #mobile-navi {
  right: 0;
}
/* LEFT */
#mobile-navi .navi {
  float: left;
  display: block;
  width: 100%;
}
#mobile-navi .navi .item {
  width: 100%;
  float: left;
  position: relative;
}
#mobile-navi .navi .item .menu {
  display: block;
  color: #fff;
  padding: 7px 0;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 20px;
}
#mobile-navi .navi .item .menu.path {
  color: #f39b00;
}
#mobile-navi .navi.sub1 {
  width: 300px;
  margin: 140px 40px 0;
}
#mobile-navi .navi.sub2 .item {
  margin-top: 3px;
}
#mobile-navi .navi.sub2 .item.init {
  margin-top: 0;
}
#mobile-navi .navi.sub2 .item.exit {
  margin-bottom: 22px;
}
#mobile-navi .navi.sub2 .item .menu {
  background: #333;
  padding: 4px 5px 2px;
}
#mobile-navi div.navi > .item.cb-toggle-target-active > div.navi {
  max-height: 1000px;
  -o-transition: all 2s;
  -moz-transition: all 2s;
  -webkit-transition: all 2s;
  transition: all 2s;
}
#mobile-navi .cb-toggle {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  width: 34px;
  height: 34px;
  background: #666666;
}
#mobile-navi .cb-toggle:hover,
#mobile-navi .cb-toggle:focus {
  cursor: pointer;
}
#mobile-navi .cb-toggle.cb-toggle-active {
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
#mobile-navi div.sub2 .cb-toggle {
  height: 34px;
}
#mobile-navi .navi > .cb-toggle,
#mobile-navi .navi .item-empty > .cb-toggle {
  display: none;
}
@media only screen and (max-width: 639px) {
  #mobile-navi {
    width: 280px;
  }
  #mobile-navi .navi.sub1 {
    width: 220px;
    margin-left: 30px;
    margin-right: 30px;
  }
}
/******* layout-small.less 2013-1-16 *******/
.desk {
  max-width: 90%;
}
@media only screen and (max-width: 849px) {
  .section--header {
    position: fixed;
    top: 0;
    left: 0;
  }
  .section--mood,
  .section--homemood {
    margin-top: 100px;
  }
}
.im-mood__claim {
  right: 50%;
  margin-right: -30px;
}
@media only screen and (max-width: 639px) {
  .section--footer {
    background-size: 100% auto;
    background-image: url(/images/foot-small.jpg);
    padding-top: 42.1875%;
  }
  .footcontent {
    margin: 0;
    padding: 24px 0;
  }
  .footarea {
    justify-content: space-between;
  }
  .footpart {
    float: right;
    margin: 30px 0 0;
  }
  .footpart.init {
    margin-right: 20px;
  }
  .footpart.exit {
    width: 100%;
  }
  .footpart.exit br:nth-child(2) {
    display: none;
  }
}
@media only screen and (max-width: 639px) {
  #expo div.link {
    bottom: 100%;
  }
  #expo div.link tr.cb-index-all {
    bottom: 0;
    margin-bottom: -34px;
    text-align: center;
  }
  #expo div.link tr.cb-index-all td:first-child {
    margin-left: 0;
  }
}
div.base div.unit.fold div.ctrl {
  left: 5%;
  width: 90%;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area .part,
.area > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 100%;
}
div.base div.unit div.head,
div.base div.unit div.body,
div.base div.unit div.foot {
  max-width: 90%;
}
div.base div.unit.fold div.body {
  max-width: 100%;
}
div.base div.unit.fold div.body div.more {
  max-width: 90%;
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
h1,
h2 {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
/******* module-statistic.less 2013-1-16 *******/
.cb-statistics .chartclient-annotation-table {
  font-size: 12px;
  font-size: 1.2rem;
}
.cb-statistic-annotation li {
  list-style: none;
}
.cb-statistics .ctrl {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.cb-statistics .ctrl input {
  margin-right: 5px;
}
.cb-statistics .ctrl label {
  margin-right: 15px;
}
.cb-statistics .ctrl a {
  float: right;
}
#root .goog-custom-button-inner-box table,
#root .chartclient-annotation-table,
#root .annotatedtimelinetable {
  margin: 0;
}
#root #scrollingListTd {
  padding: 5px 0 5px 4px;
}
#root div.cb-statistics table.horz thead th {
  border-style: none none solid;
  border-color: #555;
  background-color: transparent;
}
#root div.cb-statistics table.horz tbody td {
  padding: 2px 1px;
  border: solid #ddd;
  border-width: 1px 0 0;
}
#root div.cb-statistics table.horz .odd td {
  background-color: #f9f9f9;
}
.cb-statistics .cb-visits {
  text-align: right;
}
/******* development.less 2013-1-16 *******/
/*.unit {
  box-shadow: 0 0 0 1px red inset;
}
 .part {
   box-shadow: 0 0 0 1px blue inset;
}*/
/*# sourceMappingURL=./screen-small.css.map */